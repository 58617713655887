import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { imgix } from "@utils/imgix";

export function NextHead({ image = null, metadata = [], children = null }) {
  return (
    <Head>
      
      
      
      
      
      
      {image?.url && (
        <>
          
          
          
        </>
      )}
      {Array.isArray(metadata) &&
        metadata.map(({ tag: Tag, ...meta }) => (
          <Tag
            key={meta.name || meta.property || meta.rel || meta.href}
            {...meta}
            {...(meta.property === "og:image"
              ? { content: imgix(meta?.content, { w: 600, h: 400 }) }
              : {})}
          />
        ))}
      {children}
    </Head>
  );
}

NextHead.propTypes = {
  image: PropTypes.shape({ url: PropTypes.string }),
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      property: PropTypes.string,
      tag: PropTypes.oneOf(["link", "meta"]).isRequired,
      name: PropTypes.string,
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
